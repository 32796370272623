import { elqGetGuidCookieValue, mapProjectValue, formatContractorDataForModApi } from '../../helpers';
/**
 * This function processes form attribution data, only when submitting first step
 * @param {Object} formData - form data
 * @param {Number} currentStep - current active step index
 * @returns {Object} form data
 */
export function preprocessContractorFormData(formData, currentStep) {
	if (currentStep === 0) {
		const attributionKeys = window.modUtils.getSupportedTrackingParams();

		formData.primaryProjectClass = mapProjectValue(formData.primaryProjectClass);

		if (window.modUtils.heapCookie) {
			// Check heap cookie for tracking params and append to form
			if (window.modUtils.heapCookieData && Object.keys(window.modUtils.heapCookieData).length) {
				Object.keys(window.modUtils.heapCookieData).forEach(function(name) {
					if (attributionKeys.indexOf(name) > -1) {
						formData[name] = window.modUtils.heapCookieData[name];
					}
				});
			}
		}

		// Check Eloqua GUID cookie value and add it to formData
		const elqGUID = elqGetGuidCookieValue();

		if (elqGUID !== '') {
			formData.elqCustomerGUID = elqGUID;
		}

		// Check for TrustedForm token
		if (formData.xxTrustedFormToken) {
			const tokenMatch = formData.xxTrustedFormToken.match(/([A-Za-z0-9]{40})/);

			if (tokenMatch !== null && 'undefined' !== typeof tokenMatch[0]) {
				formData.certificationType = 'TrustedForm';
				formData.certificationToken = tokenMatch[0];
			}
		}
	}

	formData = formatContractorDataForModApi(formData, currentStep);

	return formData;
}
