import { populateTradeFromURL } from '../../../helpers';
/**
 * Init Pro Signup form
 */
export function initContractorForm() {
	window.modForm.opts.formTyCls = 'form--ty';
	window.modForm.opts.submitData = {};
	window.modForm.opts.sessionObj = {};
	window.modForm.opts.landingPage = window.modUtils.getLandingPage();

	populateTradeFromURL();
}
